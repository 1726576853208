import * as React from "react"
import Layout from '../components/Layout'
import tw from 'twin.macro' /* eslint-disable-line */

import { HeroSubTitle } from '../styles/base'


const ThanksCareerPage = () => {
  return (
    <Layout>


    <div tw='bg-gray-200 pt-8 px-7'>


      <HeroSubTitle tw='max-w-3xl  mt-5 text-center mx-auto px-7 md:px-5 pb-16 font-gibson min-h-screen'>
        Thank you for sending in your application!
      </HeroSubTitle>

    </div>

    </Layout>
  )
}

export default ThanksCareerPage
